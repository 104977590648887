<template>
  <b-tabs fill>
    <b-tab :title="$t('Inconsistências Fiscais')">
      <div>
        <e-filters
          :searching="fetching"
          @search="filterData"
          @reset="resetFiltersLocal"
        >
          <b-row>
            <b-col md="6">
              <FormulateInput
                id="ncm"
                v-model="filters.ncm"
                type="select-searchable"
                :label="$t('NCM')"
                :placeholder="$t('Digite para pesquisar')"
                :options="getComboNcms"
                autofocus
                @fetchSearch="ncmSearch"
              />
            </b-col>
            <b-col cols="2">
              <FormulateInput
                id="origin-state"
                v-model="filters.originState"
                type="vue-select"
                :label="$t('Estado de Origem')"
                :options="getComboStates"
              />
            </b-col>
          </b-row>
        </e-filters>

        <b-card-actions
          action-refresh
          :show-loading="fetching"
          @refresh="getData"
        >
          <div slot="title">
            <e-page-size-selector
              :per-page="paging.pageSize"
              @change="pageSizeChange"
            />
          </div>
          <b-table
            ref="taxClassificationsInconsistencies-table"
            show-empty
            responsive
            striped
            class="bordered"
            :empty-text="getEmptyTableMessage($tc('TAX_CLASSIFICATION.NAME'), 'female')"
            :fields="fieldsTaxClassificationInconsistencies"
            :items="taxClassificationsInconsistencies"
            no-local-sorting
            :sort-by.sync="sortInconcistencies.sortBy"
            :sort-desc.sync="sortInconcistencies.sortDesc"
            @sort-changed="getData"
          >
            <template #cell(action)="row">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="$can('Update', 'TaxClassification')"
                    @click="updateTaxClassification(row)"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>{{ $t('Corrigir') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </template>

            <template #cell(validation)="row">
              <div
                v-for="item in row.item.validations"
                :key="`${row.ncm}-${item}`"
              >
                <span>
                  {{ $t(item) }}
                </span>
                <br>
              </div>
            </template>
          </b-table>
          <b-row>
            <b-col cols="6">
              <e-pagination-summary
                :current-page="paging.currentPage"
                :per-page="paging.pageSize"
                :total="paging.rowCount"
                :total-on-page="paging.rowsInCurrentPage"
              />
            </b-col>
            <b-col cols="6">
              <b-pagination
                v-model="paging.currentPage"
                align="right"
                :total-rows="paging.rowCount"
                :per-page="paging.pageSize"
                aria-controls="taxClassifications-table"
                @change="pageChange"
              />
            </b-col>
          </b-row>
        </b-card-actions>
      </div>
    </b-tab>
    <b-tab :title="$t('Produtos sem Classificação Fiscal')">
      <div>
        <b-card-actions
          action-refresh
          :show-loading="fetching"
          @refresh="getProductData"
        >
          <b-table
            ref="products-table"
            show-empty
            responsive
            striped
            class="bordered"
            :empty-text="getEmptyTableMessage($tc('TAX_CLASSIFICATION.NAME'), 'female')"
            :fields="fieldsProductsWithoutTaxClassification"
            :items="products"
            no-local-sorting
            :sort-by.sync="sorting.sortBy"
            :sort-desc.sync="sorting.sortDesc"
            @sort-changed="getProductData"
          />
        </b-card-actions>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BTabs,
  BTab,
  BTable,
  BPagination,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { EPageSizeSelector, EPaginationSummary } from '@/views/components'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import { stringUtils } from '@/mixins'
import { mapGetters, mapActions, mapState } from 'vuex'
import EFilters from '@/views/components/EFilters.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BTabs,
    BTab,
    BTable,
    BPagination,
    BRow,
    BCol,
    BCardActions,
    EPageSizeSelector,
    EPaginationSummary,
    EFilters,
  },
  directives: { Ripple },
  mixins: [stringUtils],
  data() {
    return {
      loaded: false,
      fetching: false,
    }
  },

  computed: {
    ...mapState('pages/invoice/taxClassifications', [
      'taxClassificationsInconsistencies',
      'reportData',
      'paging',
      'sorting',
      'sortInconcistencies',
      'filters',
    ]),
    ...mapState('pages/invoice/taxClassifications/ProductWithoutTaxClassification', ['products']),

    ...mapGetters('common/states', ['getComboStates', 'getComboStatesWithAll']),
    ...mapGetters('pages/invoice/taxClassifications', ['getComboOperationTypes']),
    ...mapGetters('common/ncms', ['getComboNcms']),

    fieldsTaxClassificationInconsistencies() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '40px' },
        },
        {
          label: this.$t('Validation'),
          key: 'validation',
        },
        { label: this.$t('Ncm'), key: 'ncm', class: 'text-center', sortable: true },
        {
          label: this.$t('Estado de Origem'),
          key: 'originState',
          class: 'text-center',
          sortable: true,
        },
        { label: this.$t('Cfop de Saída'), key: 'cfopOutput', class: 'text-center' },
        { label: this.$t('Cfop de Entrada'), key: 'cfopInput', class: 'text-center' },
        { label: this.$t('Cst Icms'), key: 'stIcms', class: 'text-center' },
        {
          label: this.$t('Total Produtos'),
          key: 'totalProducts',
          class: 'text-center',
          sortable: true,
        },
      ]
    },
    fieldsProductsWithoutTaxClassification() {
      return [
        { label: this.$t('Id'), key: 'id', class: 'text-center' },
        { label: this.$t('Ean'), key: 'ean', class: 'text-center' },
        { label: this.$t('Ncm'), key: 'ncm', class: 'text-center', sortable: true },
        { label: this.$t('Nome'), key: 'name', class: 'text-center' },
        { label: this.$t('Estados sem Classificação'), key: 'missingStates', class: 'text-center' },
      ]
    },
  },

  mounted() {
    this.fetchStates()
    this.fetchTaxClassificationCombos()
    if (!this.taxClassificationsInconsistencies.length) {
      this.getData()
    }
    if (!this.products.length) {
      this.getProductData()
    }
    setTimeout(() => {
      this.loaded = true
    }, 500)
  },

  methods: {
    ...mapActions('common/states', ['fetchStates']),
    ...mapActions('common/ncms', ['fetchNcms']),
    ...mapActions('pages/invoice/taxClassifications', [
      'fetchTaxClassificationCombos',
      'fetchTaxClassificationsInconsistencies',
      'setPageSize',
      'setCurrentPage',
      'resetFilters',
    ]),
    ...mapActions('pages/invoice/taxClassifications/ProductWithoutTaxClassification', [
      'fetchProducts',
    ]),

    async ncmSearch(loading, search) {
      try {
        loading(true)
        await this.fetchNcms({ itemSearch: search })
      } catch (e) {
        this.showError({
          message: this.$t('Não foi possível fazer a pesquisa'),
        })
      } finally {
        loading(false)
      }
    },
    async getProductData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchProducts()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },
    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchTaxClassificationsInconsistencies()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    updateTaxClassification(taxClassification) {
      this.$router.push({
        name: 'tax-classification-maintain',
        params: {
          ncm: taxClassification.item.ncm,
          originState: taxClassification.item.originState,
          operationType: taxClassification.item.operationType,
          destinationState: taxClassification.item.destinationState,
        },
      })
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },
    filterData() {
      this.setCurrentPage(1)
      this.refreshData()
    },

    filterProductData() {
      this.refreshProductData()
    },
    refreshData() {
      this.getData()
    },
    refreshProductData() {
      this.getProductData()
    },
  },
}
</script>
